import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { PiSealCheckDuotone } from "react-icons/pi";
import { MdOutlineDesignServices } from "react-icons/md";
import { LuTimerReset } from "react-icons/lu";
import { RiScissorsCutLine } from "react-icons/ri";
import { IoPricetagsOutline } from "react-icons/io5";
import { MdOutlineWorkHistory } from "react-icons/md";

import { BeforeAfter } from "react-simple-before-after";

import After_Slider_1 from "../assets/img/after-before/Clipping-Path/after-1.webp";
import After_Slider_2 from "../assets/img/after-before/Clipping-Path/after-2.webp";
import After_Slider_3 from "../assets/img/after-before/Clipping-Path/after-3.webp";
import After_Slider_4 from "../assets/img/after-before/Clipping-Path/after-4.webp";
import After_Slider_5 from "../assets/img/after-before/Clipping-Path/after-5.webp";
import After_Slider_6 from "../assets/img/after-before/Clipping-Path/after-6.webp";
import After_Slider_7 from "../assets/img/after-before/Clipping-Path/after-7.webp";
import After_Slider_8 from "../assets/img/after-before/Clipping-Path/after-8.webp";
import After_Slider_9 from "../assets/img/after-before/Clipping-Path/after-9.webp";
import After_Slider_10 from "../assets/img/after-before/Clipping-Path/after-10.webp";

import Before_Slider_1 from "../assets/img/after-before/Clipping-Path/before-1.webp";
import Before_Slider_2 from "../assets/img/after-before/Clipping-Path/before-2.webp";
import Before_Slider_3 from "../assets/img/after-before/Clipping-Path/before-3.webp";
import Before_Slider_4 from "../assets/img/after-before/Clipping-Path/before-4.webp";
import Before_Slider_5 from "../assets/img/after-before/Clipping-Path/before-5.webp";
import Before_Slider_6 from "../assets/img/after-before/Clipping-Path/before-6.webp";
import Before_Slider_7 from "../assets/img/after-before/Clipping-Path/before-7.webp";
import Before_Slider_8 from "../assets/img/after-before/Clipping-Path/before-8.webp";
import Before_Slider_9 from "../assets/img/after-before/Clipping-Path/before-9.webp";
import Before_Slider_10 from "../assets/img/after-before/Clipping-Path/before-10.webp";

import Service_accodian_png from "../assets/img/professional-clippping-path-servic.png";
import Service_accodian from "../assets/img/professional-clippping-path-service.webp";

import Logo_png from "../assets/img/logo.png";
import Logo_webp from "../assets/img/logo.webp";
import { lazy, Suspense } from "react";
import Img_Icon from "../assets/img/img-icon.webp";
import Img_Icon_1 from "../assets/img/banner-icon-1.webp";
import Img_Icon_2 from "../assets/img/banner-icon-2.webp";
import Img_Icon_3 from "../assets/img/banner-icon-3.webp";
import Img_Icon_4 from "../assets/img/banner-icon-4.webp";

import Img_Banner_1 from "../assets/img/image-clipping-banner.webp";

import Testi_1 from "../assets/img/testi-icon-1.webp";
import Feature_New_1 from "../assets/img/feature-new-3.webp";

import Satisfied from "../assets/img/100-satisfied.webp";
import Marquee from "react-fast-marquee";

import Company_1 from "../assets/img/px-conversions/company-1.webp";
import Company_2 from "../assets/img/px-conversions/company-2.webp";
import Company_3 from "../assets/img/px-conversions/company-3.webp";
import Company_4 from "../assets/img/px-conversions/company-4.webp";
import Company_5 from "../assets/img/px-conversions/company-5.webp";
import Company_6 from "../assets/img/px-conversions/company-6.webp";
import Company_7 from "../assets/img/px-conversions/company-7.webp";
import { BsShieldFillCheck } from "react-icons/bs";

import Gallery_img_1 from "../assets/img/protfolio/clipping-path/gallery-1.webp";

import Gallery_img_2 from "../assets/img/protfolio/clipping-path/gallery-2.webp";

import Gallery_img_3 from "../assets/img/protfolio/clipping-path/gallery-3.webp";

import Gallery_img_4 from "../assets/img/protfolio/clipping-path/gallery-4.webp";

import { Helmet } from "react-helmet";

import Gallery_img_5 from "../assets/img/protfolio/clipping-path/gallery-5.webp";

import { LazyLoadImage } from "react-lazy-load-image-component";
import pMinDelay from "p-min-delay";

const About_setion2 = lazy(() => pMinDelay(import("./home/About_setion.js"), 5000));

const Clipping_Path = () => {
  var settings_1 = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      <Helmet>
        <title>Outsource Photo Clipping Path Services | Top Image Clipping Path Services</title>
        <meta name="description" content="In the United States, France, and the United Kingdom, Visuals Clipping offers deep etching and high-quality image Photoshop clipping path services at the most competitive rates." />
        <link rel="canonical" href="https://www.visualsclipping.com/image-clipping-path-service" />
        {/* <meta name="robots" content="noindex, nofollow, noimageindex, max-snippet:-1, max-video-preview:-1" /> */}
      </Helmet>
      <section className="c-banner-w">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="c-banner-con">
                <h5>
                  <img src={Img_Icon} alt="visuals clipping " />
                  Clipping Path
                </h5>
                <h1>
                  <span>Clipping Path </span> Services!
                </h1>

                <ul>
                  <li>
                    <div className="c-banner-feature">
                      <span>
                        <img src={Img_Icon_1} alt="visuals clipping " />
                      </span>
                      100% Satisfaction
                    </div>
                  </li>
                  <li>
                    <div className="c-banner-feature">
                      <span>
                        <img src={Img_Icon_2} alt="visuals clipping " />
                      </span>
                      No Advanced Payment
                    </div>
                  </li>
                  <li>
                    <div className="c-banner-feature">
                      <span>
                        <img src={Img_Icon_3} alt="visuals clipping " />
                      </span>
                      Unlimited Revision
                    </div>
                  </li>
                  <li>
                    <div className="c-banner-feature">
                      <span>
                        <img src={Img_Icon_4} alt="visuals clipping " />
                      </span>
                      10 Image Free Trail
                    </div>
                  </li>
                </ul>

                <div className="c-banner-btn">
                  <Link to="/contact" className="c-btn-1">
                    <span> Get Quote Now</span>
                  </Link>
                  <button data-bs-toggle="modal" data-bs-target=".c-model-popup-1" className="c-btn-2">
                    <span> Get a Free Trial</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="c-banner-slider">
                <div className="c-banner-slider-list-w">
                  <Slider {...settings_1}>
                    <div className="c-banner-slider-list">
                      <LazyLoadImage
                        alt={"visuals clipping"}
                        height={729}
                        src={Img_Banner_1}
                        width={679}
                        wrapperProps={{
                          style: { transitionDelay: "0.1s" },
                        }}
                      />
                    </div>
                    <div className="c-banner-slider-list">
                      <LazyLoadImage
                        alt={"visuals clipping"}
                        height={729}
                        src={Img_Banner_1}
                        width={679}
                        wrapperProps={{
                          style: { transitionDelay: "0.1s" },
                        }}
                      />
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="c-about-w c-featurw-pw">
        <div className="container">
          <div className="c-about-hw">
            <h2>
              Benefits to outsource Photo Retouching <br></br> Services to <span>Clipping Path</span>{" "}
            </h2>
            <p>ou are not restricted to choices when you choose Visuals Clipping. Our clients receive personalized services as per their needs.</p>
          </div>
          <div className="cp-fea-list">
            <div className="cp-fea-list-box">
              <div className="cp-fea-list-box-in">
                <span>
                  <LuTimerReset />
                </span>
                Time Saving
              </div>
            </div>
            <div className="cp-fea-list-box">
              <div className="cp-fea-list-box-in">
                <span>
                  <RiScissorsCutLine />
                </span>
                Accuracy
              </div>
              <div className="cp-fea-list-box-in">
                <span>
                  <MdOutlineDesignServices />
                </span>
                100% <br></br>Customized <br></br> Service
              </div>
            </div>

            <div className="cp-fea-list-box c-fea-midel">
              <div className="cp-fea-list-box-in">
                <picture>
                  <source srcset={Logo_webp} type="image/webp" alt="visuals clipping logo" />
                  <source srcset={Logo_png} type="image/jpeg" alt="visuals clipping logo" />
                  <img src={Logo_png} alt="yazzoo homepage banner" />
                </picture>
              </div>
            </div>
            <div className="cp-fea-list-box c-fea-right">
              <div className="cp-fea-list-box-in">
                <span>
                  <MdOutlineWorkHistory />
                </span>
                Professional <br></br> Work
              </div>
              <div className="cp-fea-list-box-in">
                <span>
                  <IoPricetagsOutline />
                </span>
                Cost Effective
              </div>
            </div>
            <div className="cp-fea-list-box c-fea-right">
              <div className="cp-fea-list-box-in">
                <span>
                  <BsShieldFillCheck />
                </span>
                Highest <br></br>standard
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="c-service-accodian">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="c-service-img">
                <LazyLoadImage
                  alt={"visuals clipping"}
                  height={571}
                  src={Service_accodian}
                  width={857}
                  wrapperProps={{
                    style: { transitionDelay: "0.1s" },
                  }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="c-about-hw c-faq-group-in c-service-faq">
                <h2>
                  100 % Accurate HandMade <span>Clipping Path</span> Services{" "}
                </h2>
                <p>
                  {" "}
                  Finding a reliable clipping path company is not an easy task. Especially if you want to find a provider who can offer you Best Clipping Path Service at an affordable price.Visuals Clipping is a leading clipping path company. We offer a comprehensive range of image-clipping path
                  services to our clients.
                </p>
                <p>
                  By working with us, clients can get bulk image clipping support to finish large volumes timely. Visuals Clipping has been delivering high-quality photoshopclipping path, image masking, deep etch, channel masking, detouragephoto, and other retouching services for 10 years. In that
                  time, we have served clients from all over the world, including the USA, the United Kingdom, Germany, France, Canada, Norway, Belgium, South Africa, Switzerland, and many other countries. We are proud to say that we have maintained a high level of customer satisfaction since we
                  started offering photo retouching and clipping path services. We guarantee that you will be satisfied with our work and commitment that produces extraordinary results.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="c-service-tab-w">
        <div className="container">
          <div className="c-heading-w">
            <h2>
              Quick and   <span>Effective</span> Clipping Path Service{" "}
            </h2>
            <p>Are you in need of high-quality photo editing and retouching services?Look no further than us! Here are the top reasons why we should be your go-to choose for all your photo editing and retouching needs:</p>
          </div>
          <div className="c-service-tab">
            <ul class="nav nav-pills mb-4" id="pills-tab" role="tablist">
              <li class="nav-item" role="presentation">
                <button class="nav-link active" data-bs-toggle="pill" data-bs-target="#cp-tab-1" type="button" role="tab" aria-selected="true">
                  Simple
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" data-bs-toggle="pill" data-bs-target="#cp-tab-4" type="button" role="tab" aria-selected="false">
                  Medium
                </button>
              </li>

              <li class="nav-item" role="presentation">
                <button class="nav-link" data-bs-toggle="pill" data-bs-target="#cp-tab-2" type="button" role="tab" aria-selected="false">
                  Multiple
                </button>
              </li>

              <li class="nav-item" role="presentation">
                <button class="nav-link " data-bs-toggle="pill" data-bs-target="#cp-tab-5" type="button" role="tab" aria-selected="true">
                  {" "}
                  Masking{" "}
                </button>
              </li>

              <li class="nav-item" role="presentation">
                <button class="nav-link " data-bs-toggle="pill" data-bs-target="#cp-tab-3" type="button" role="tab" aria-selected="true">
                  Complex
                </button>
              </li>
            </ul>
            <div class="tab-content" id="pills-tabContent">
              <div class="tab-pane fade show active" id="cp-tab-1" role="tabpanel" tabindex="0">
                <div className="row">
                  <div className="col-md-6">
                    <BeforeAfter
                      beforeImage={Before_Slider_10}
                      afterImage={After_Slider_10}
                      /* Other Props */
                    />
                  </div>
                  <div className="col-md-6">
                    <BeforeAfter beforeImage={Before_Slider_9} afterImage={After_Slider_9} />
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="cp-tab-2" role="tabpanel" tabindex="0">
                <div className="row">
                  <div className="col-md-6">
                    <BeforeAfter
                      beforeImage={Before_Slider_5}
                      afterImage={After_Slider_5}
                      /* Other Props */
                    />
                  </div>
                  <div className="col-md-6">
                    <BeforeAfter beforeImage={Before_Slider_8} afterImage={After_Slider_8} />
                  </div>
                </div>
              </div>

              <div class="tab-pane fade " id="cp-tab-4" role="tabpanel" tabindex="0">
                <div className="row">
                  <div className="col-md-6">
                    <BeforeAfter
                      beforeImage={Before_Slider_6}
                      afterImage={After_Slider_6}
                      /* Other Props */
                    />
                  </div>
                  <div className="col-md-6">
                    <BeforeAfter beforeImage={Before_Slider_7} afterImage={After_Slider_7} />
                  </div>
                </div>
              </div>
              <div class="tab-pane fade " id="cp-tab-3" role="tabpanel" tabindex="0">
                <div className="row">
                  <div className="col-md-6">
                    <BeforeAfter
                      beforeImage={Before_Slider_1}
                      afterImage={After_Slider_1}
                      /* Other Props */
                    />
                  </div>
                  <div className="col-md-6">
                    <BeforeAfter beforeImage={Before_Slider_2} afterImage={After_Slider_2} />
                  </div>
                </div>
              </div>

              <div class="tab-pane fade " id="cp-tab-5" role="tabpanel" tabindex="0">
                <div className="row">
                  <div className="col-md-6">
                    <BeforeAfter
                      beforeImage={Before_Slider_3}
                      afterImage={After_Slider_3}
                      /* Other Props */
                    />
                  </div>
                  <div className="col-md-6">
                    <BeforeAfter beforeImage={Before_Slider_4} afterImage={After_Slider_4} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="cn-feature-main cp-service-feature">
        <div className="container">
          <div className="c-feature-w">
            <div className="c-heading-w">
              <h2>
                What <span>We Offer</span>{" "}
              </h2>
              <p>Are you in need of high-quality photo editing and retouching services?Look no further than us! Here are the top reasons why we should be your go-to choose for all your photo editing and retouching needs: </p>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="c-feature-box">
                  <LazyLoadImage
                    alt={"visuals clipping"}
                    height={105}
                    src={Feature_New_1}
                    width={90}
                    wrapperProps={{
                      style: { transitionDelay: "0.1s" },
                    }}
                  />

                  <h3>High-quality Hand-made clipping path</h3>
                  <p>
                    Hire Visuals Clipping to receive the best-handcrafted clipping path service. A high-quality hand-made clipping path makes your products look great, as you can remove the background of an image and use it with other backgrounds or photos. It can help create other marketing
                    materials like product photos and infographics.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="c-feature-box">
                  <LazyLoadImage
                    alt={"visuals clipping"}
                    height={105}
                    src={Feature_New_1}
                    width={90}
                    wrapperProps={{
                      style: { transitionDelay: "0.1s" },
                    }}
                  />

                  <h3>Medium complexity Photo clipping path</h3>
                  <p>
                    As the name suggests, a medium photo detourage or clipping path is used to make a clipping path around the subject matter of medium complexity. Our medium clipping path is processed by the photoshop pen tool giving you 100% accuracy. Our manual clipping paths services help
                    businesses, individuals, and e-commerce companies to edit their photos professionally. Clipping Path Service allows them to achieve the perfect look for their products and websites while maintaining a high-quality image. Our services are ideal for print media houses, who can use
                    our clipping path service to create stunning visuals for their magazines and newspapers. Our services help save time and money for our clients as we provide high-quality photos ready for publication or posting.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="c-feature-box">
                  <LazyLoadImage
                    alt={"visuals clipping"}
                    height={105}
                    src={Feature_New_1}
                    width={90}
                    wrapperProps={{
                      style: { transitionDelay: "0.1s" },
                    }}
                  />

                  <h3>Clipping Path for Complex Images?</h3>
                  <p>
                    Visuals Clipping is a pioneer company to outsource your entire clipping path or image masking work. We have been serving clipping path services globally since 2011. We always process the images in photoshop and deliver perfect clipping path images with 100% accuracy. A complex
                    clipping path is a closed vector path or shape that cuts out an intricate image from its background. A difficult clipping path can have many different points and curves. Complex clipping path makes them ideal for cutting out images with lots of detail, like bikes, cars, hair, or
                    other super complex products. Our services are suitable not only for simple or medium product clipping paths but also dependable for intricate product clipping paths. So, when it comes to detourage complex product photos, Image Manipulation, Image Optimization, Fashion
                    Retouching, or even something like Jewelry Photo Retouching, where you need a complex or detailed clipping path, you can always count on us.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="c-feature-box">
                  <LazyLoadImage
                    alt={"visuals clipping"}
                    height={105}
                    src={Feature_New_1}
                    width={90}
                    wrapperProps={{
                      style: { transitionDelay: "0.1s" },
                    }}
                  />

                  <h3>Require multiple clipping paths?</h3>
                  <p>
                    A multiple-clipping path is a complex clipping path with additional paths carefully crafted within a single product or image. We use photoshop pen tool to produce multiple clipping path to separate the different parts of any product or photo. Multiple clipping paths can be used
                    to isolate each part of the product or object, making it easier to manipulate the image in post-production. We provide multiple clipping path services that meet your needs and exceed your expectations. Our skilled team of 
                    <a href="https://visualsstock.co.in/photo-background-removal-service">photoshop professionals</a> will work diligently to provide you the flawless results, and our global clients can contact us to experience.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="c-feature-box">
                  <LazyLoadImage
                    alt={"visuals clipping"}
                    height={105}
                    src={Feature_New_1}
                    width={90}
                    wrapperProps={{
                      style: { transitionDelay: "0.1s" },
                    }}
                  />

                  <h3>Image masking & channel masking</h3>
                  <p>
                    Image masking and channel masking services offered by Visuals Clipping help you make image composites, modify the background, cut out objects, and target edits so they only affect specific areas rather than the whole layer. Using it, you can hide parts of an image or layer
                    without erasing them. Best results guaranteed!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="c-testimonial-w">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="c-testi-in">
                <div className="c-heading-w">
                  <h5>
                    <img src={Testi_1} alt="visuals clipping " /> Testimonial
                  </h5>
                  <h2>
                    What Our <span>Client</span> Says?
                  </h2>
                  <p>With our AI workflows, scaling to meet your photo processing needs is easy!</p>
                  <a href="#" className="c-btn-1">
                    <span> Contact</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="c-testi-list">
                <div className="slider-container">
                  <Suspense fallback={<p> Loding </p>}>
                    <About_setion2 />
                  </Suspense>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="c-price-table-w">
        <div className="container">
          <div className="c-heading-w">
            <h2>
              Clipping Path <span> Price Chart </span>{" "}
            </h2>
          </div>
          <div class="table-responsive">
            <table class="table table-bordered">
              <tr>
                <th>Work</th>
                <th>Basic ($ 0.6/image)</th>
                <th>Medium ($ 1/image)</th>
                <th>Complex ($ 1.5 /image)</th>
              </tr>
              <tr>
                <td>
                  {" "}
                  <strong>Product Type</strong>{" "}
                </td>
                <td>Simple</td>
                <td>Medium</td>
                <td>Complex</td>
              </tr>

              <tr>
                <td>
                  {" "}
                  <strong>Dust / Reflection removal</strong>{" "}
                </td>
                <td>
                  <span>
                    <PiSealCheckDuotone />
                  </span>
                </td>
                <td>
                  <span>
                    <PiSealCheckDuotone />
                  </span>
                </td>
                <td>
                  <span>
                    <PiSealCheckDuotone />
                  </span>
                </td>
              </tr>

              <tr>
                <td>
                  {" "}
                  <strong>Drop Shadow / Reflection</strong>{" "}
                </td>
                <td>
                  <span></span>
                </td>
                <td>
                  <span>
                    <PiSealCheckDuotone />
                  </span>
                </td>
                <td>
                  <span>
                    <PiSealCheckDuotone />
                  </span>
                </td>
              </tr>

              <tr>
                <td>
                  {" "}
                  <strong>Image Resizing</strong>{" "}
                </td>
                <td>
                  <span>
                    <PiSealCheckDuotone />
                  </span>
                </td>
                <td>
                  <span>
                    <PiSealCheckDuotone />
                  </span>
                </td>
                <td>
                  <span>
                    <PiSealCheckDuotone />
                  </span>
                </td>
              </tr>

              <tr>
                <td>
                  {" "}
                  <strong>Maximum Image Size</strong>{" "}
                </td>
                <td>1500X1500</td>
                <td>2000X2000</td>
                <td>No Size Limit</td>
              </tr>

              <tr>
                <td>
                  {" "}
                  <strong>Image Enhancement</strong>{" "}
                </td>
                <td>
                  <span>
                    <PiSealCheckDuotone />
                  </span>
                </td>
                <td>
                  <span>
                    <PiSealCheckDuotone />
                  </span>
                </td>
                <td>
                  <span>
                    <PiSealCheckDuotone />
                  </span>
                </td>
              </tr>

              <tr>
                <td>
                  {" "}
                  <strong>Color Correction</strong>{" "}
                </td>
                <td>
                  <span></span>
                </td>
                <td>
                  <span></span>
                </td>
                <td>
                  <span>
                    <PiSealCheckDuotone />
                  </span>
                </td>
              </tr>

              <tr>
                <td>
                  {" "}
                  <strong>100% Customized Retouching</strong>{" "}
                </td>
                <td>
                  <span></span>
                </td>
                <td>
                  <span></span>
                </td>
                <td>
                  <span>
                    <PiSealCheckDuotone />
                  </span>
                </td>
              </tr>

              <tr>
                <td>
                  {" "}
                  <strong>Image padding</strong>{" "}
                </td>
                <td>
                  <span>
                    <PiSealCheckDuotone />
                  </span>
                </td>
                <td>
                  <span>
                    <PiSealCheckDuotone />
                  </span>
                </td>
                <td>
                  <span>
                    <PiSealCheckDuotone />
                  </span>
                </td>
              </tr>

              <tr>
                <td>
                  {" "}
                  <strong>Image Renaming</strong>{" "}
                </td>
                <td></td>
                <td></td>
                <td>
                  <span>
                    <PiSealCheckDuotone />
                  </span>
                </td>
              </tr>

              <tr>
                <td>
                  {" "}
                  <strong>Multiple Format (JPG, PNG, Tiff, PSD, Other)</strong>{" "}
                </td>
                <td>
                  <span></span>
                </td>
                <td>
                  <span></span>
                </td>
                <td>
                  <span>
                    <PiSealCheckDuotone />
                  </span>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </section>

      <section className="c-portfolio-service">
        <div className="container">
          <div className="c-heading-w">
            <h2>
              Our Photo Editing  <span>Portfolio </span>{" "}
            </h2>
            <p>Transforming Ordinary Images into Extraordinary Visuals.</p>
          </div>
          <div className="c-porfolio-in">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="c-porfolio-box">
                  <LazyLoadImage
                    alt={"visuals clipping"}
                    height={670}
                    src={Gallery_img_1}
                    width={857}
                    wrapperProps={{
                      style: { transitionDelay: "0.1s" },
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <div className="c-porfolio-box">
                      <LazyLoadImage
                        alt={"visuals clipping"}
                        height={328}
                        src={Gallery_img_2}
                        width={417}
                        wrapperProps={{
                          style: { transitionDelay: "0.1s" },
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="c-porfolio-box">
                      <LazyLoadImage
                        alt={"visuals clipping"}
                        height={328}
                        src={Gallery_img_3}
                        width={417}
                        wrapperProps={{
                          style: { transitionDelay: "0.1s" },
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="c-porfolio-box">
                      <LazyLoadImage
                        alt={"visuals clipping"}
                        height={328}
                        src={Gallery_img_4}
                        width={417}
                        wrapperProps={{
                          style: { transitionDelay: "0.1s" },
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="c-porfolio-box">
                      <LazyLoadImage
                        alt={"visuals clipping"}
                        height={328}
                        src={Gallery_img_5}
                        width={417}
                        wrapperProps={{
                          style: { transitionDelay: "0.1s" },
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center">
              <Link to="/contact" className="c-btn-1">
                <span>Get a Free Trial</span>{" "}
              </Link>
            </div>
          </div>
          <div className="c-porfolio-con">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="c-porfolio-con">
                  <div className="c-heading-w text-left">
                    <h2>
                      Save time and money by outsourcing to <span>Visuals Clipping!</span>
                    </h2>
                    <p>
                      A clipping path is a graphical technique used to define an image's precise interior or exterior region to isolate or remove it from the rest of the picture. For example, we use Photoshop pen tool technique for clipping path to remove the image's unwanted background or cut out a
                      specific object from a photo. Before deciding to move forward with any company to outsource your photo-clipping path projects, you can get a free trial to check the quality of our service. We give you a 50 images free trial facility to ensure quality, accuracy, and delivery
                      time. We work at the most competitive rates compared to the industry.
                    </p>
                    <p>
                      Are you looking for a reliable company to outsource your clipping path projects? Visuals Clipping specializes in simple or essential photo clipping path or detourage services. We take pride in our work and guarantee outstanding results. Our skilled Photoshop professionals can
                      deliver any clipping path project quickly and efficiently. We have worked with various clients, including eCommerce companies throughout the glove, print media, online jewelry stores, online used car sellers/dealers, and other online businesses. No matter what your products
                      are, we can help you achieve the professional look for your product which will help to increase your sales
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="c-about-w c-inner-company">
        <div className="container">
          <div className="c-about-hw">
            <h2>
              Our Trusted <span>Customers</span>{" "}
            </h2>

            <LazyLoadImage
              alt={"visuals clipping"}
              height={82}
              src={Satisfied}
              width={224}
              wrapperProps={{
                style: { transitionDelay: "0.1s" },
              }}
            />
          </div>
          <Marquee>
            <LazyLoadImage
              alt={"visuals clipping"}
              height={30}
              src={Company_1}
              width={96}
              wrapperProps={{
                style: { transitionDelay: "0.1s" },
              }}
            />
            <LazyLoadImage
              alt={"visuals clipping"}
              height={30}
              src={Company_2}
              width={96}
              wrapperProps={{
                style: { transitionDelay: "0.1s" },
              }}
            />
            <LazyLoadImage
              alt={"visuals clipping"}
              height={30}
              src={Company_3}
              width={96}
              wrapperProps={{
                style: { transitionDelay: "0.1s" },
              }}
            />
            <LazyLoadImage
              alt={"visuals clipping"}
              height={30}
              src={Company_4}
              width={96}
              wrapperProps={{
                style: { transitionDelay: "0.1s" },
              }}
            />
            <LazyLoadImage
              alt={"visuals clipping"}
              height={30}
              src={Company_5}
              width={96}
              wrapperProps={{
                style: { transitionDelay: "0.1s" },
              }}
            />
            <LazyLoadImage
              alt={"visuals clipping"}
              height={30}
              src={Company_6}
              width={96}
              wrapperProps={{
                style: { transitionDelay: "0.1s" },
              }}
            />
            <LazyLoadImage
              alt={"visuals clipping"}
              height={30}
              src={Company_7}
              width={96}
              wrapperProps={{
                style: { transitionDelay: "0.1s" },
              }}
            />
          </Marquee>
        </div>
      </section>
    </>
  );
};

export default Clipping_Path;
